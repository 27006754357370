import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { getSubscribers } from '../../api/subscribers';
import { subscribersInitApiSettings } from '../../api/subscribers/config';
import { getSystem } from '../../api/systems';
import CommonHead from '../../components/commonHead';
import SubscribersTable from '../../components/subscribers/subscribersTable';
import Button from '../../components/ui/button';
import { ISelectOption } from '../../components/ui/select/types';
import {
  companyUrl,
  getObjectSystemIdUrl,
  getObjectSystemsListUrl,
  getSubscribersAllUrl,
  getSubscribersInObjectUrl,
  subscriberExcelExportUrl,
} from '../../constants/api';
import { paths } from '../../constants/paths';
import { selectAllOptionKey } from '../../constants/select';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useApi } from '../../hooks/useApi';
import { checkIsAdmin, getProfilePermission } from '../../store/selectors/profile';
import { setHeaderTitle } from '../../store/slices/header';
import {
  ApiFilterCommand,
  ApiFilterOperator,
  IApiExtendSetting,
  IApiFilter,
  IApiResponse,
  IApiSortField,
} from '../../typings/api';
import { ESidebarItemIds } from '../../typings/sidebar';
import { ISubscriber, ISubscribersErrorData } from '../../typings/subscribers';
import { ISystem } from '../../typings/systems/system';
import { ESubscribersTabsIds } from './types';
import { getFileRequest, getRequest } from '../../api';
import { EPageQueryParams } from '../../typings/searchParams';
import { ButtonType } from '../../components/ui/button/types';
import DownloadIcon from '../../assets/svg/icons/download';
import UpArrow from '../../assets/svg/icons/upArrow';
import SubscribersUploadModal from '../../components/subscribers/subscribersUploadModal';
import SubscribersErrorsModal from '../../components/subscribers/subscribersErrorsModal';
import FiltersTool from '../../components/ui/filtersTool';
import { EFilterTool } from '../../typings/filtersTool';

const Subscribers: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const permissionSubscribers = useAppSelector(getProfilePermission(ESidebarItemIds.subscribers));

  const isAdmin = useAppSelector(checkIsAdmin);

  const {
    data: subscribers,
    loading,
    sendRequest: sendSubscribersRequest,
  } = useApi<IApiResponse<ISubscriber>>(getSubscribers);
  const { data: systems, sendRequest: sendSystemRequest } = useApi<IApiResponse<ISystem>>(getSystem);
  const { data: companies, sendRequest: getCompanies } = useApi<ISystem[]>(getRequest);

  const [searchValue, setSearchValue] = useState<string>('');
  const [companyId, setCompanyId] = useState<string | number>('');
  const [selectedSystemId, setSelectedSystemId] = useState<string | number>(selectAllOptionKey);
  const [apiSettings, setApiSettings] = useState<IApiExtendSetting<ISubscriber>>(subscribersInitApiSettings);

  const [uploadModalOpen, setUploadLogoModalOpen] = useState(false);

  const [errorsModalOpen, setErrorsModalOpen] = useState(false);

  const [errorsData, setErrorsData] = useState<ISubscribersErrorData | null>(null);

  const { data: exportData, sendRequest: getExcelFile, loading: loadingGetExcelFile } = useApi(getFileRequest);

  useEffect(() => {
    dispatch(setHeaderTitle('Абоненты'));
  }, [dispatch]);

  useEffect(() => {
    sendSubscribersRequest(getSubscribersAllUrl(), apiSettings);
    sendSystemRequest(getObjectSystemsListUrl(), { params: { count: 0, isOnlyIpSeries: true } });
  }, [sendSubscribersRequest, sendSystemRequest]);

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl());
    }
  }, [isAdmin]);

  const setErrorsDataAndOpenModal = useCallback((data: ISubscribersErrorData) => {
    setErrorsData(data);
    setErrorsModalOpen(true);
  }, []);

  const requestSubscribers = useCallback(
    async (reqSettings: IApiExtendSetting<ISubscriber>, systemId: string, newCompanyId: string) => {
      if (systemId === selectAllOptionKey) {
        console.log(reqSettings);
        await sendSubscribersRequest(getSubscribersAllUrl(), reqSettings, { params: { companyId: newCompanyId } });
      } else {
        await sendSubscribersRequest(getSubscribersInObjectUrl(systemId), reqSettings, {
          params: { companyId: newCompanyId },
        });
      }
    },
    [sendSubscribersRequest]
  );

  const getPhoneSearchValue = useCallback((phone: string) => {
    const number = phone?.replace('+', '');
    if (number?.substring(0, 1) === '8') {
      return `7${number.substring(1)}`;
    }
    return number;
  }, []);

  const getSearchApiFilters = useCallback(
    (
      fields: (
        | keyof ISubscriber
        | 'subscriberObjects.flats.address'
        | 'subscriberObjects.flats.number'
        | 'subscriberObjects.flats.buildingAddress'
      )[],
      value: string
    ): IApiFilter<ISubscriber>[] =>
      fields.map((field) => ({
        name: field,
        command: ApiFilterCommand.contain,
        search: field === 'phone' ? getPhoneSearchValue(value) : value,
        operator: ApiFilterOperator.or,
      })),
    [getPhoneSearchValue]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearchValue(value);
      let newApiSettings: IApiExtendSetting<ISubscriber> = { ...apiSettings };
      if (!value) {
        newApiSettings = { ...newApiSettings, filters: [] };
      } else {
        newApiSettings = {
          ...newApiSettings,
          page: 0,
          filters: getSearchApiFilters(
            [
              'firstName',
              'lastName',
              'middleName',
              'phone',
              'email',
              'subscriberObjects.flats.address',
              'subscriberObjects.flats.number',
              'subscriberObjects.flats.buildingAddress',
            ],
            value
          ),
        };
      }
      await requestSubscribers(newApiSettings, selectedSystemId.toString(), companyId?.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, companyId, getSearchApiFilters, requestSubscribers, selectedSystemId]
  );

  const handleOnChangeSystem = useCallback(
    async (systemId: string | number) => {
      setSelectedSystemId(systemId);
      const newApiSettings: IApiExtendSetting<ISubscriber> = {
        ...subscribersInitApiSettings,
        sortFields: apiSettings.sortFields,
      };
      setApiSettings(newApiSettings);
      await requestSubscribers(newApiSettings, systemId?.toString(), companyId?.toString());
    },
    [apiSettings, companyId, requestSubscribers]
  );

  const handleOnChangeCompanyId = useCallback(
    async (id: string | number) => {
      setCompanyId(id);
      const newApiSettings: IApiExtendSetting<ISubscriber> = {
        ...subscribersInitApiSettings,
        sortFields: apiSettings.sortFields,
      };
      setApiSettings(newApiSettings);
      await requestSubscribers(newApiSettings, '', id?.toString());
      setSelectedSystemId('');
      await sendSystemRequest(id ? getObjectSystemIdUrl(id.toString()) : getObjectSystemsListUrl(), {
        params: { count: 0, isOnlyIpSeries: true },
      });
    },
    [apiSettings.sortFields, requestSubscribers, sendSystemRequest]
  );

  const onAddSubscriber = useCallback(() => navigate(paths.subscribersNew), [navigate]);

  const navigateToSubscriberPage = useCallback(
    (subscriberId: string) => {
      navigate({
        pathname: `${paths.subscribers}/${subscriberId}`,
        search: `?${createSearchParams({ [`${EPageQueryParams.tabId}`]: ESubscribersTabsIds.basic })}`,
      });
    },
    [navigate]
  );

  const handleOnEditRow = useCallback(
    (subscriberId: string) => navigateToSubscriberPage(subscriberId),
    [navigateToSubscriberPage]
  );

  const handleOnClickRow = useCallback(
    (subscriberId: string) => navigateToSubscriberPage(subscriberId),
    [navigateToSubscriberPage]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IApiExtendSetting<ISubscriber> = { ...apiSettings, page: page - 1 };
      await requestSubscribers(newApiSettings, selectedSystemId?.toString(), companyId?.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, companyId, requestSubscribers, selectedSystemId]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<ISubscriber>[]) => {
      const newApiSettings: IApiExtendSetting<ISubscriber> = { ...apiSettings, sortFields: sortResults };
      await requestSubscribers(newApiSettings, selectedSystemId?.toString(), companyId?.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, companyId, requestSubscribers, selectedSystemId]
  );

  const saveFile = useCallback(async () => {
    if (exportData) {
      const fileOptions = {
        suggestedName: 'Subscribers.xlsx',
        types: [
          {
            description: 'xlsx',
            accept: {
              'application/msexcel': '.xlsx',
            },
          },
        ],
        excludeAcceptAllOption: true,
      };
      const fileHandle = await (window as any).showSaveFilePicker(fileOptions);
      const writableStream = await fileHandle.createWritable();
      await writableStream.write(exportData.data);
      await writableStream.close();
    }
  }, [exportData]);

  useEffect(() => {
    saveFile();
  }, [exportData]);

  const handleOnDownload = useCallback(() => {
    getExcelFile(subscriberExcelExportUrl());
  }, [getExcelFile]);

  const handleOnUpload = useCallback(() => {
    setUploadLogoModalOpen(true);
  }, []);

  const renderRangesMenu = useCallback(
    () => (
      <Menu
        className="subscribers__range-dropdown"
        items={[
          {
            label: 'Добавить абонента',
            key: 'add-subscriber',
            className: 'subscribers__range-dropdown-item',
            onClick: onAddSubscriber,
          },
          {
            label: 'Загрузить через Excel',
            key: 'upload-excel',
            className: 'subscribers__range-dropdown-item',
            onClick: handleOnUpload,
          },
        ]}
      />
    ),
    [onAddSubscriber, handleOnUpload]
  );

  const objectSelectItems = useMemo(
    () => [
      { value: selectAllOptionKey, data: 'Все', title: 'Все' },
      ...(systems?.items?.map<ISelectOption>((system) => ({
        value: system.id || '',
        title: system.shortName || system.objectName || '',
      })) || []),
    ],
    [systems?.items]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin && companies
        ? companies.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  const afterUpload = useCallback(() => {
    setUploadLogoModalOpen(false);
    requestSubscribers(apiSettings, selectedSystemId?.toString(), companyId?.toString());
  }, [apiSettings, companyId, requestSubscribers, selectedSystemId]);

  const setValuesFromUrl = useCallback(
    async (values: any) => {
      setSearchValue(values.search || '');
      setCompanyId(values.companyId || '');
      setSelectedSystemId(values.systemId || selectAllOptionKey);

      let filters: any[] = [];

      if (values.search) {
        filters = getSearchApiFilters(
          [
            'firstName',
            'lastName',
            'middleName',
            'phone',
            'email',
            'subscriberObjects.flats.address',
            'subscriberObjects.flats.number',
            'subscriberObjects.flats.buildingAddress',
          ],
          values.search
        );
      }

      const newApiSettings = {
        ...apiSettings,
        page: 0,
        filters: filters,
      };

      setApiSettings(newApiSettings);

      if (values.companyId)
        await sendSystemRequest(
          values.companyId ? getObjectSystemIdUrl(values.companyId.toString()) : getObjectSystemsListUrl(),
          {
            params: { count: 0, isOnlyIpSeries: true },
          }
        );

      await requestSubscribers(newApiSettings, values.systemId || '', values.companyId || '');
    },
    [apiSettings, getSearchApiFilters, requestSubscribers, sendSystemRequest]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Абоненты' }} />
      <SubscribersUploadModal
        afterUpload={afterUpload}
        setErrorsData={setErrorsDataAndOpenModal}
        isOpen={uploadModalOpen}
        onCancel={() => setUploadLogoModalOpen(false)}
      />
      <SubscribersErrorsModal
        isOpen={errorsModalOpen}
        onCancel={() => setErrorsModalOpen(false)}
        errorsData={errorsData}
      />
      <div className="subscribers">
        <FiltersTool
          setValuesWithDelay
          wrapperClassName="subscribers__tools"
          setValuesFromUrl={setValuesFromUrl}
          rows={[
            [
              {
                type: EFilterTool.search,
                id: 'search',
                props: {
                  containerClassName: 'subscribers__search',
                  placeholder: 'Поиск по ФИО, телефону, E–mail, адресу',
                  value: searchValue,
                  onSearch: handleOnSearch,
                },
              },
              {
                type: EFilterTool.select,
                id: 'companyId',
                hidden: !isAdmin,
                props: {
                  isAllOption: true,
                  title: 'Организация',
                  value: companyId,
                  onChange: handleOnChangeCompanyId,
                  options: organizationOptions,
                },
              },
              {
                type: EFilterTool.select,
                id: 'systemId',
                props: {
                  title: 'Краткое наименование объекта',
                  containerClassName: 'subscribers__select',
                  onChange: handleOnChangeSystem,
                  value: selectedSystemId,
                  options: objectSelectItems,
                },
              },
              {
                type: EFilterTool.extraContent,
                props: {
                  children: (
                    <>
                      {permissionSubscribers?.import && (
                        <Button
                          rightIcon={<DownloadIcon />}
                          onClick={handleOnDownload}
                          type={ButtonType.outline}
                          className="subscribers__button"
                          loading={loadingGetExcelFile}
                        >
                          Скачать шаблон
                        </Button>
                      )}
                      {permissionSubscribers?.import && permissionSubscribers?.create && (
                        <Dropdown overlay={renderRangesMenu} trigger={['click']}>
                          <Button
                            rightIcon={
                              <span className="subscribers__button-icon">
                                <UpArrow />
                              </span>
                            }
                          >
                            Добавить абонента
                          </Button>
                        </Dropdown>
                      )}
                      {permissionSubscribers?.create && !permissionSubscribers?.import && (
                        <Button onClick={onAddSubscriber} className="subscribers__button" loading={loadingGetExcelFile}>
                          Добавить абонента
                        </Button>
                      )}
                      {!permissionSubscribers?.create && permissionSubscribers?.import && (
                        <Button onClick={handleOnUpload} className="subscribers__button" loading={loadingGetExcelFile}>
                          Импорт абонентов
                        </Button>
                      )}
                    </>
                  ),
                },
              },
            ],
          ]}
        />
        <div className="subscribers__table">
          <SubscribersTable
            subscribers={subscribers?.items || []}
            loading={loading}
            onEditRow={handleOnEditRow}
            onClickRow={handleOnClickRow}
            onSort={handleOnSort}
            sortOrders={apiSettings.sortFields}
            permission={permissionSubscribers}
            pagination={{
              pageSize: subscribers?.pageSize || 0,
              currentPage: subscribers?.page || 0,
              total: subscribers?.totalCount || 0,
              onChangePage: handleOnChangeTablePage,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Subscribers;
